import { Container, Group, ActionIcon, rem, Image } from '@mantine/core';
import { IconBrandTwitter, IconBrandYoutube, IconBrandInstagram } from '@tabler/icons-react';
import classes from './Footer.module.css';
import logo from '../logo.svg';

export function Footer() {
  return (
    <div className={classes.footer}>
      <Container className={classes.inner}>
        <Image height={28} src={logo} />
        <Group gap={0} className={classes.links} justify="flex-end" wrap="nowrap">
          {/* <ActionIcon size="lg" color="gray" variant="subtle">
            <IconBrandTwitter style={{ width: rem(18), height: rem(18) }} stroke={1.5} />
          </ActionIcon>
          <ActionIcon size="lg" color="gray" variant="subtle">
            <IconBrandYoutube style={{ width: rem(18), height: rem(18) }} stroke={1.5} />
          </ActionIcon> */}
          <ActionIcon size="lg" color="gray" variant="subtle" component="a" href="https://instagram.com/arthurdesignco">
            <IconBrandInstagram style={{ width: rem(18), height: rem(18) }} stroke={1.5} />
          </ActionIcon>
        </Group>
      </Container>
    </div>
  );
}