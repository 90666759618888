import './App.css';
import { Footer } from './components/Footer';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          Arthur Design Co. - Under Construction
        </p>
        
      </header>
      <body>
        <p>
          For Inquiries
        </p>
        <p>
          Email <a href="mailto:haley@arthurdesign.co">haley@arthurdesign.co</a>
        </p>
        <p>
          Instagram <a href="https://instagram.com/arthurdesignco">@arthurdesignco</a>
        </p>
      </body>
      <Footer></Footer>
    </div>
  );
}

export default App;
